/* eslint-disable santa/no-side-effects */
define(['lodash', 'prop-types', 'componentsCore', 'coreUtils', 'santa-components', 'backgroundCommon/mixins/videoPlayerMixin'], function (_, PropTypes, componentsCore, coreUtils, santaComponents, videoPlayerMixin) {
    'use strict';

    const consts = coreUtils.mediaConsts;

    function isVideoPartRendered(parts) {
        return _.includes(parts, 'video');
    }

    function getInitialPlaybackState(videoNode, isAutoplay) {
        const isLoading = videoNode.readyState <= 2;
        const playback = isAutoplay ? consts.playbackTypes.PLAYING : consts.playbackTypes.READY;
        return isLoading ? consts.playbackTypes.LOADING : playback;
    }

    function getLayoutData(props) {
        const tagName = props.renderType === 'bolt' ? 'wix-video' : 'div';
        const hasVideoData = isVideoPartRendered(props.videoRenderParts);
        let layoutProps = {isVideoDataExists: ''};

        if (hasVideoData) {
            const {staticVideoUrl, format, parentId, fittingType, alignType, hasBgScrollEffect, compData, compProp} = props;
            const videoQualitiesSorted = _(compData.qualities)
                .reject({quality: 'storyboard'})
                .sortBy(qualityItem => parseInt(qualityItem.quality, 10))
                .value();

            const qualitiesForMeasures = videoQualitiesSorted.map(item => ({
                quality: item.quality,
                size: item.width * item.height,
                url: item.url
            }));

            const qualityItem = _.last(videoQualitiesSorted);
            const isAutoplay = compProp.autoplay || compData.autoplay;
            layoutProps = {
                fittingType,
                alignType,
                hasBgScrollEffect,
                animatePoster: compProp.animatePoster,
                staticVideoUrl,
                videoId: compData.videoId,
                playbackRate: Math.max(0, compData.playbackSpeed || 1),
                playerType: 'html5',
                isVideoDataExists: '1',
                videoWidth: qualityItem.width,
                videoHeight: qualityItem.height,
                qualities: qualitiesForMeasures,
                videoFormat: format,
                autoPlay: props.isPlayingAllowed && isAutoplay,
                isEditorMode: props.isEditorMode,
                isViewerMode: props.isViewerMode,
                containerId: parentId
            };
        }

        return {
            tagName,
            'data-video-info': JSON.stringify(layoutProps)
        };
    }

    /**
     * @class components.html5Video
     * @extends {core.skinBasedComp}
     */
    return {
        displayName: 'html5Video',
        mixins: [videoPlayerMixin, componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin],
        propTypes: _.defaults(
            {
                compData: PropTypes.object.isRequired,
                compProp: PropTypes.object,
                parentId: PropTypes.string.isRequired,
                playFullscreen: PropTypes.bool,
                videoRenderParts: PropTypes.array,
                format: PropTypes.string,
                config: PropTypes.object,
                playbackUrl: PropTypes.string,
                notifyMediaState: PropTypes.func.isRequired,
                notifyVideoVisibility: PropTypes.func.isRequired,
                showMedia: PropTypes.bool.isRequired,
                shouldRenderSrc: PropTypes.bool,
                posterWidth: PropTypes.number,
                posterHeight: PropTypes.number,
                setMediaAPI: PropTypes.func.isRequired,
                registerMediaSource: PropTypes.func,
                keepVideoHidden: PropTypes.bool,
                hasBgScrollEffect: PropTypes.bool,
                isPlayingAllowed: PropTypes.bool,
                isEditorMode: PropTypes.bool,
                fittingType: PropTypes.string,
                alignType: PropTypes.string,
                renderType: santaComponents.santaTypesDefinitions.PublicModel.renderType,
                isViewerMode: santaComponents.santaTypesDefinitions.isViewerMode.isRequired,
                staticVideoUrl: santaComponents.santaTypesDefinitions.ServiceTopology.staticVideoUrl,
                adaptiveVideoDomain: santaComponents.santaTypesDefinitions.ServiceTopology.adaptiveVideoDomain

            },
            santaComponents.utils.santaTypesUtils.getSantaTypesFromPropTypes(santaComponents.components.Image.propTypes)
        ),

        // Lifecycle

        getInitialState() {
            this.playWhenReady = false;
            this.hls = null;
        },

        componentDidMount() {
            const videoNode = this.refs.video;
            const isAutoplay = this.isAutoplay();
            this.handleMute();
            this.initTimeUpdateListener();
            this.props.notifyMediaState({
                type: consts.eventTypes.MOUNT,
                duration: videoNode.duration || this.props.compData.duration,
                muted: videoNode.muted,
                playbackState: getInitialPlaybackState(videoNode, isAutoplay),
                previousPlaybackState: isAutoplay ? consts.playbackTypes.PLAYING : ''
            });
            if (isVideoPartRendered(this.props.videoRenderParts)) {
                this.initAdaptive();
                this.setAdaptiveVideoSource();
                this.setRate(this.props.compData.playbackSpeed || 1);
            }

            this.props.setMediaAPI(this.mediaAPI);

            //Expose the video element to media parent
            if (this.props.registerMediaSource) {
                this.props.registerMediaSource(videoNode);
            }
        },

        componentDidUpdate() {
            if (isVideoPartRendered(this.props.videoRenderParts)) {
                this.initAdaptive();
                this.setAdaptiveVideoSource();
                this.setRate(this.props.compData.playbackSpeed || 1);
                // render might have changed attributes that did not trigger any event,
                // we compensate for that.
                this.props.notifyMediaState({
                    type: consts.eventTypes.LOAD,
                    looped: this.props.compProp.loop,
                    duration: this.refs.video.duration || this.props.compData.duration
                });
            }
        },

        componentWillUnmount() {
            this.props.setMediaAPI(null);
            this.props.notifyVideoVisibility(false);
            if (isVideoPartRendered(this.props.videoRenderParts)) {
                this.refs.video.removeEventListener('timeupdate', this.handlePosterVisibilityOnce);
                this.removeVideoSecurely();
            }
        },

        /**
         *  register to timeupdate in order to remove the poster
         */
        initTimeUpdateListener() {
            const videoNode = this.refs.video;
            //register to timeupdate in order to remove the poster
            if (_.includes(this.props.compData.mediaFeatures, 'alpha')) {
                videoNode.addEventListener('timeupdate', this.handlePosterVisibilityOnce);
            }
        },

        shouldInitiateAdaptive() {
            // return truthy when 1. format is hls 2. framework isnt initiated already
            return this.props.format === 'hls' && !_.get(this.hls, 'media');
        },

        /**
         * for adaptive streams , set and load the video url if needed
         */
        setAdaptiveVideoSource() {
            if (this.hls) {
                const hlsDataItem = _.find(this.props.compData.adaptiveVideo, {format: 'hls'});
                const hlsUrl = coreUtils.urlUtils.joinURL(this.props.adaptiveVideoDomain, _.get(hlsDataItem, 'url'));
                //'http://files-test.wix.com/files/video/5d38f6_15b514b5ec3a4e4893b60299ee160d03/repackage/hls'
                if (hlsUrl !== this.hls.url) {
                    this.hls.loadSource(hlsUrl);
                }
            }
        },

        initAdaptive() {
            if (this.shouldInitiateAdaptive()) {
                // HLS JS framework
                //todo: find a nicer way to get or keep the Hls instance
                // load Hls module synchronous since it already loaded
                //eslint-disable-next-line
                const Hls = requirejs('hls-light');
                this.hls = new Hls(_.clone(this.props.config.hls));
                this.hls.attachMedia(this.refs.video);
                this.hls.on(Hls.Events.ERROR, this.onHlsError);
            }
        },

        isAutoplay() {
            return (this.props.compProp.autoplay || this.props.compData.autoplay) && this.props.isPlayingAllowed;
        },

        /**
         * Hide the poster when video playback starts
         * NOTE: this event removes itself once it meets the conditions which invokes it
         */
        handlePosterVisibilityOnce() {
            //MSE + HLS.JS bug https://jira.wixpress.com/browse/WEED-9688 ,
            // Safari & FF sets the current time > 0 while video is paused
            if (!this.refs.video.paused && this.refs.video.currentTime > 0) {
                this.refs.video.removeEventListener('timeupdate', this.handlePosterVisibilityOnce);
                this.props.notifyMediaState({
                    type: consts.eventTypes.PLAYSTATE,
                    playbackState: consts.playbackTypes.PLAYING
                });
                this.refs.video.addEventListener('seeked', this.setPosterState);
                this.seek(0);
            }
        },

        setPosterState() {
            this.refs.video.removeEventListener('seeked', this.setPosterState);
            this.props.notifyVideoVisibility(true);
        },

        handleMute() {
            if (!this.shouldMute()) {
                this.refs.video.muted = false;
            }
        },

        resetPosterState() {
            if (this.props.showMedia) {
                this.props.notifyVideoVisibility(false);
                this.refs.video.addEventListener('timeupdate', this.handlePosterVisibilityOnce);
            }
        },

        /**
         * To prevent weird browser bugs, remove the video "securely" -
         * Remove every video source and reload the video object with empty sources
         */
        removeVideoSecurely() {
            this.refs.video.pause();
            if (this.hls) {
                this.hls.detachMedia();
                this.hls.destroy();
            }
            this.refs.video.src = '';
            this.refs.video.load();
        },

        /**
         * Return true if video has at least one frame
         * @returns {boolean}
         */
        canVideoPlay() {
            return this.refs.video.readyState >= this.refs.video.HAVE_CURRENT_DATA;
        },

        /**
         * Should video be muted by properties in data or props of the component
         * @returns {boolean}
         */
        shouldMute() {
            return this.props.compProp.disableAudio ||
                _.get(this.props, ['compProp', 'mute'], _.get(this.props, ['compData', 'mute'])) ||
                this.props.compData.hasAudio === false;
        },

        // API

        // mediaAPI() moved to videoPlayerMixin.

        /**
         * Play the video
         * if video is not ready to play tag it to play when ready.
         */
        play() {
            if (this.canVideoPlay()) {
                this.refs.video.play();
            } else {
                this.playWhenReady = true;
            }
        },

        /**
         * Pause the video
         */
        pause() {
            this.playWhenReady = false;
            this.refs.video.pause();
        },

        /**
         * Stop the video (Pause and set play head to 0)
         */
        stop() {
            this.pause();
            this.seek(0);
            this.resetPosterState();
        },

        /**
         * Set the volume
         * @param {number} volume - min: 0, max: 1
         */
        setVolume(volume) {
            this.refs.video.volume = Math.max(0, Math.min(1, volume));
        },

        /**
         * Mute the video
         */
        mute() {
            this.refs.video.muted = true;
        },

        /**
         * Un-mute the video if audio is enabled and video has audio
         */
        unMute() {
            this.refs.video.muted = this.props.compProp.disableAudio;
        },

        /**
         * Seek the video
         * @param {number} time in seconds - min: 0, max: video duration
         */
        seek(time) {
            this.refs.video.currentTime = Math.max(0, Math.min(time, this.refs.video.duration || this.props.compData.duration));
        },

        /**
         * Change the video speed
         * @param {number} speed the video speed, min: 0
         */
        setRate(speed) {
            this.refs.video.playbackRate = Math.max(0, speed);
        },

        // Event Listeners

        /**
         * On the first time the video element loads
         * Happens only once per load() video event
         */
        onLoadStart() {
            this.props.notifyMediaState({
                type: consts.eventTypes.LOAD,
                playbackState: consts.playbackTypes.LOADING,
                volume: this.refs.video.volume,
                muted: this.refs.video.muted
            });
        },

        onDurationChange() {
            this.props.notifyMediaState({
                type: consts.eventTypes.LOAD,
                duration: parseInt(this.refs.video.duration * 100, 10) / 100
            });
        },

        /**
         * When the first frame loads, check if player should autoplay, else get into READY state
         * Happens only once per load() video event
         */
        onLoadedData() {
            if (this.isAutoplay()) {
                this.props.notifyMediaState({
                    type: consts.eventTypes.LOAD,
                    previousPlaybackState: consts.playbackTypes.PLAYING
                });
            } else {
                this.props.notifyMediaState({
                    type: consts.eventTypes.LOAD,
                    playbackState: consts.playbackTypes.READY
                });
            }
        },

        /**
         * When the video can play, check if it received a command to play when it couldn't and play
         * For autoplay, play after seek etc.
         */
        onCanPlay() {
            if (this.playWhenReady) {
                this.play();
                this.playWhenReady = false;
            }
        },

        /**
         * when the video reaches the end of its play head and is not looped
         */
        onPlayEnded() {
            this.props.notifyMediaState({
                type: consts.eventTypes.PLAYSTATE,
                playbackState: consts.playbackTypes.PLAY_ENDED
            });
        },

        /**
         * On every time the playback changes from paused to playing
         */
        onPlay() {
            this.props.notifyMediaState({
                type: consts.eventTypes.PLAYSTATE,
                playbackState: consts.playbackTypes.PLAYING
            });
        },

        /**
         * On every time the playback changes from playing to paused
         */
        onPause() {
            this.props.notifyMediaState({
                type: consts.eventTypes.PLAYSTATE,
                playbackState: consts.playbackTypes.PAUSED
            });
        },

        /**
         * On HLS error events
         * @param {Event} event
         * @param {object} data, event data
         */
        onHlsError(event, data) {
            if (data.fatal) {
                this.refs.video.src = '';
                this.props.notifyMediaState({
                    type: consts.eventTypes.ERROR,
                    error: consts.errorTypes.NO_HLS_VIDEO
                });
            }
        },

        /**
         * On error events
         * @param {Event} event
         */
        onError(event) {
            if (event.currentTarget.networkState === event.currentTarget.NETWORK_NO_SOURCE) {
                this.props.notifyMediaState({
                    type: consts.eventTypes.ERROR,
                    error: consts.errorTypes.NO_VIDEO_FOUND
                });
            } else {
                this.props.notifyMediaState({
                    type: consts.eventTypes.ERROR,
                    error: consts.errorTypes.VIDEO_GENERAL_ERROR
                });
            }
        },

        /**
         * On every volume change
         */
        onVolumeChange() {
            this.props.notifyMediaState({
                type: consts.eventTypes.VOLUME,
                volume: this.refs.video.volume,
                muted: this.refs.video.muted
            });
        },

        onVideoClick(event) {
            event.stopPropagation();
        },

        // Render
        /* eslint-disable complexity */
        getVideo() {
            //  iOS and Chrome flicker when switching opacity on video 0 -> 1, so start with 0.01
            let opacity = this.props.showMedia ? 1 : 0.01;

            if (this.props.keepVideoHidden) {
                opacity = 0;
            }

            const video = {
                preload: this.props.compData.preload || 'none',
                onEnded: this.onPlayEnded,
                onError: this.onError,
                onLoadStart: this.onLoadStart,
                onLoadedData: this.onLoadedData,
                onCanPlay: this.onCanPlay,
                onDurationChange: this.onDurationChange,
                onPause: this.onPause,
                onPlay: this.onPlay,
                onClick: this.onVideoClick,
                onVolumeChange: this.onVolumeChange,
                playsInline: !this.props.playFullscreen,
                crossOrigin: 'anonymous',
                style: {
                    visibility: this.props.keepVideoHidden ? 'hidden' : '',
                    position: 'absolute',
                    top: 0,
                    opacity
                }
            };
            if (this.props.compProp.loop) {
                video.loop = true;
            }

            if (this.props.compData.alt) {
                video['aria-label'] = this.props.compData.alt;
            }

            if (this.shouldMute()) {
                video.muted = 'muted';
            }

            return video;
        },

        getRenderParts() {
            const skinParts = {
                video: null,
                poster: null
            };
            _.forEach(this.props.videoRenderParts, part => {
                switch (part) {
                    case 'video':
                        skinParts.video = this.getVideo();
                        break;
                    case 'poster':
                        skinParts.poster = this.getPosterImageComp();
                        break;
                }
            });
            return skinParts;
        },

        getSkinProperties() {
            const outerStyle = {
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0
            };

            const parts = this.getRenderParts();

            return {
                '': _.assign(getLayoutData(this.props), {style: outerStyle}),
                'video': parts.video,
                'poster': parts.poster
                /*,
                itemPropName: {
                    content: this.props.compData.title || ''
                },
                itemPropDesc: {
                    content: this.props.compData.alt || 'Video'
                },
                itemPropThumb: {
                    content: ''//_.get(this.props.compData, ['posterImageRef', 'uri'])
                },
                itemPropDate: {
                    content: ''//'2018-02-20'
                }*/
            };
        }
    };
});
